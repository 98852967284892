import { ConfigStateService, featuresFactory, RoutesService, noop } from '@abp/ng.core';
import { InjectionToken, inject, APP_INITIALIZER } from '@angular/core';
import { setModuleVisibilityFactory } from '@volo/abp.commercial.ng.ui/config';
const AUDIT_LOGGING_FEATURES = new InjectionToken('AUDIT_LOGGING_FEATURES', {
  providedIn: 'root',
  factory: () => {
    const configState = inject(ConfigStateService);
    const featureKey = 'AuditLogging.Enable';
    const mapFn = features => ({
      enable: features[featureKey].toLowerCase() !== 'false'
    });
    return featuresFactory(configState, [featureKey], mapFn);
  }
});
const SET_AUDIT_LOGGING_ROUTE_VISIBILITY = new InjectionToken('SET_AUDIT_LOGGING_ROUTE_VISIBILITY', {
  providedIn: 'root',
  factory: () => {
    const routes = inject(RoutesService);
    const stream = inject(AUDIT_LOGGING_FEATURES);
    setModuleVisibilityFactory(stream, routes, "AbpAuditLogging::Menu:AuditLogging" /* eAuditLoggingRouteNames.AuditLogging */).subscribe();
  }
});
const AUDIT_LOGGING_FEATURES_PROVIDERS = [{
  provide: APP_INITIALIZER,
  useFactory: noop,
  deps: [SET_AUDIT_LOGGING_ROUTE_VISIBILITY],
  multi: true
}];

/**
 * Generated bundle index. Do not edit.
 */

export { AUDIT_LOGGING_FEATURES, AUDIT_LOGGING_FEATURES_PROVIDERS, SET_AUDIT_LOGGING_ROUTE_VISIBILITY };
